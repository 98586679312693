@import "../../Styles/variables.scss";

.orders-main {
    margin: 40px 0;
    h2 {
        text-align: left;
        margin-top: 40px;
        font-weight: 600;
        padding-bottom: 25px;
        border-bottom: 2px solid #e2e2e2;
    }
    .details-order-invoice {
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
        align-items: center;
        .invoice-details {
            p {
                margin-bottom: 7px;
            }
        }
        .invoice-text {
            p {
                color: $color-background;
                font-size: 18px;
                cursor: pointer;
                margin-top: 30px;
            }
        }
    }
    .table-responsive {
        .table {
            tbody {
                tr {
                    td {
                        .product-test-base {
                            display: flex;
                            align-items: center;
                            .test-base-img {
                                width: 110px;
                                height: 110px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                overflow: hidden;
                                img {
                                    width: 100%;
                                }
                            }
                            p {
                                margin-left: 10px;
                                font-size: 18px;
                                font-weight: 500;
                            }
                        }
                        .track-order-loc {
                                li {
                                    position: relative;
                                    line-height: 40px;
                                    &::after {
                                        position: absolute;
                                        content: "";
                                        background-color: #000;
                                        width: 2px;
                                        height: 34px;
                                        left: -16px;
                                        top: -16px;
                                    }
                                    &:first-child {
                                        &::after {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        .feedback-icon{
                            color:#800020;
                            font-size: 20px;
                            cursor: pointer;
                        }
                        .rating{
                            cursor: pointer;
                            .react-simple-star-rating-tooltip{
                                padding: 0px 5px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .groupof-method-summary {
        border-top: 1px solid #dbdbdb;
        padding-top: 25px;
        .address-group-sum {
            .payment-stripe {
                display: flex;
                align-items: center;
                .payment-stripe-img {
                    width: 60px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 100%;
                    }
                }
                p {
                    margin-bottom: 0;
                }
            }
            .amount-details-digit {
                display: flex;
                justify-content: space-between;
                color: #707070;
                &.bold {
                    font-weight: 600;
                    color: #000;
                }
            }
        }
        h4 {
            margin-bottom: 20px;
        }
    }

    .feedback-section{
        .help-text{
            font-size: 12px;
        }
        textarea{
            &.error{
              border-color: red;
            }
        }
        p{
            &.error-validation{
                font-size: 14px;
                left: 0;
                margin: 0px;
                position: inherit;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .orders-main {
        margin: 0;
        h2 {
            margin: 10px 0px;
            padding-bottom: 5px;
            font-size: 24px;
        }
        .table-responsive {
            .table {
                thead{
                    tr{
                        th{
                            font-size: 14px;
                            padding: 5px;
                            text-align: center;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            font-size: 12px;
                            padding: 5px;
                            vertical-align: middle;
                            text-align: center;
                            .product-test-base {
                                display: flex;
                                flex-direction: column;
                                .test-base-img{
                                    width: 100px;
                                    height: 100px;
                                }
                                p {
                                    font-size: 12px;
                                }
                            }
                            button{
                                &.red-btn-border{
                                    padding: 3px 20px;
                                }
                            }
                            .feedback-icon{
                                font-size: 14px;
                            }
                            .rating{
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .groupof-method-summary {
            border-top: 1px solid #dbdbdb;
            padding-top: 15px;
            .address-group-sum {
                .payment-stripe {
                    display: flex;
                    align-items: center;
                    .payment-stripe-img {
                        width: 60px;
                        height: 60px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 100%;
                        }
                    }
                    p {
                        margin-bottom: 0;
                    }
                }
                .amount-details-digit {
                    display: flex;
                    justify-content: space-between;
                    color: #707070;
                    &.bold {
                        font-weight: 600;
                        color: #000;
                    }
                    p{
                        margin-bottom: 4px;
                    }
                }
            }
            h4 {
                margin-bottom: 5px;
                font-size: 20px;
            }
            p{
                font-size: 14px;
            }
        }

        .details-order-invoice {
            margin: 15px 0;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            .track-order-box{
                width: 100%;
                .track-order-icon{
                    height: 50px;
                    width: 50px;
                    min-width: 50px;
                    .fas{
                        font-size: 16px;
                    }
                }
                p {
                    font-size: 12px;
                    font-weight: 600;
               }
            }
            .invoice-details{
                h4{
                    font-size: 18px;
                }
                p{
                    font-size: 14px;
                }
            }
            .red-btn{
                width: 50%;
                padding: 5px;
            }
        }
    }
}
