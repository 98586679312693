@import "../../Styles/variables.scss";

.profile-main {
    margin-bottom: 70px;
    position: relative;
    @media screen and (max-width: 767px) {
        margin-bottom: 15px;
    }
    h2 {
        text-align: left;
        margin-top: 40px;
        font-weight: 600;
        padding-bottom: 15px;
        border-bottom: 2px solid #e2e2e2;
        @media screen and (max-width: 767px) {
            margin: 15px 0px;
            padding: 0px;
            font-size: 24px;
        }
    }
    form {
        margin-top: 30px;
        @media screen and (max-width: 767px) {
            margin-top: 15px;
        }
        .form-group {
            position: relative;
            label {
                width: 90%;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                    color: $color-text;
                    font-size: 14px;
                    cursor: pointer;
                }
            }
            input {
                height: 45px;
                border: 1px solid #2B2B2B;
                border-radius: 10px;
                padding-left: 20px;
                margin-bottom: 30px;
                width: 90%;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
    .address-group {
        margin: 70px 0;
        .payment{
            img{
                width: 8%;
            }
            p{
                font-size: 14px;
                color: #444;
            }
            .card-detail-container{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                img{
                    width: 50%;
                }
                .card-detail{
                    .ending{
                        font-size: 14px;
                        color: #444;
                    }

                }
                .red-btn-border{
                    padding: 0px 10px;
                    .spinner-border {
                        font-size: 12px;
                        width: 15px !important;
                        height: 15px !important;
                    }
                }
            }
            .stripe-container{
                #payment-form{
                   form{
                       margin: 0;
                       display: flex;
                       align-items: center;
                       #card-element{
                           width: 88%;
                           padding: 10px 0px 2px 0px;
                       }
                       #submit {
                           width: 25%;
                           margin-top: 5px !important;
                           padding: 0;
                           &[disabled] {
                               background-color: #800020c2;
                           }
                           .spinner-border {
                               font-size: 12px;
                               width: 15px !important;
                               height: 15px !important;
                           }
                       }
                   }
                    .card-error{
                        color: red;
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            margin-top: 15px;
            margin-bottom: 15px;
        }
        .dash-address {
            border: 1px dashed #b7b7b7;
            min-height: 300px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            color: #707070;
            cursor: pointer;
            span {
                font-size: 30px;
                color: #b7b7b7;
            }
        }
        .address-desc {
            box-shadow: 0px 5px 20px #00000029;
            padding: 20px;
            color: #707070;
            min-height: 300px;
            @media screen and (max-width: 767px) {
                padding-bottom: 0;
            }
            .default-desc {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                h4 {
                    margin-bottom: 0;
                }
                p {
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 700;
                    color: $color-background;
                    display: flex;
                    align-items: center;
                    span {
                        font-size: 26px;
                        margin-right: 10px;
                    }
                }
            }
            .address-crud {
                margin-top: 50px;
                @media screen and (max-width: 767px) {
                    margin-top: 15px;
                }
                ul {
                    display: flex;
                    padding-left: 0;
                    list-style: none;
                    justify-content: center;
                    li {
                        display: flex;
                        align-items: center;
                        border-right: 1px solid #707070;
                        height: 36px;
                        justify-content: center;
                        padding: 20px;
                        color: $color-background;
                        font-weight: 500;
                        cursor: pointer;
                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }
            &.active {
                border: 5px solid $color-background;
                background-color: #0981800A;
            }
        }
    }
    .modal {
        .modal-dialog {
            .modal-content {
                padding: 12px 30px;
                @media screen and (max-width: 767px) {
                    padding: 0px 10px;
                }
                .modal-body {
                    .change-form {
                        margin-top: 0;
                        @media screen and (max-width: 767px) {
                            button.red-btn{
                                width: 100%;
                            }
                        }
                        .form-group {
                            position: relative;
                            margin-bottom: 30px;
                            input {
                                width: 100%;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    .modal {
        // .modal-dialog {
            .form-group {
                position: relative;
                margin-bottom: 30px;
                input {
                    width: 100%;
                    margin-bottom: 0px;
                }
            }
        // }
    }
    .PhoneInput{
        .PhoneInputCountry{
            display: none;
        }
        .PhoneInputInput{
            margin-bottom: 0;
            &:focus{
                background-color: #fff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem #80bdff87;
            }
        }
    }
}
