@import "../../Styles/variables.scss";

.contact-main {
    margin: 40px 0;
    h2 {
        text-align: left;
        font-weight: 600;
    }
    p {
        padding-bottom: 25px;
        margin-bottom: 40px;
    }
    form {
        width: 50%;
        margin: 0 auto;
        .contact-heading {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
        }
        .form-group {
            position: relative;
            margin-bottom: 35px;
            label {
                font-size: 20px;
                sup {
                    color: red;
                }
            }
            input {
                height: 45px;
                border: 1px solid #2B2B2B;
                border-radius: 10px;
                padding-left: 20px;
            }
            textarea {
                border: 1px solid #2B2B2B;
                border-radius: 10px;
                resize: none;
            }
        }
        .PhoneInput{
            .PhoneInputCountry{
                display: none;
            }
            .PhoneInputInput{
                margin-bottom: 0;
                &:focus{
                    background-color: #fff;
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem #80bdff87;
                }
            }
        }
    }
    button {
        width: 100%;
        margin-top: 20px;
    }
}

@media screen and (max-width: 767px) {
    .contact-main {
        margin: 15px 0;
        h2 {
            font-size: 24px;
        }
        p {
            padding-bottom: 0px;
            margin-bottom: 10px;
            &.error-validation{
                bottom: -20px;
                left: 0;
            }
        }
        form {
            width: 100%;
            .contact-heading {
                margin-bottom: 0px;
                justify-content: center;
                .contact-img{
                    width: 20%;
                    img{
                        width: 100%;
                    }
                }
            }
            .form-group {
                position: relative;
                margin-bottom: 20px;
                label {
                    font-size: 15px;
                    margin-bottom: 1px;
                    sup {
                        color: red;
                    }
                }
                input {
                    height: 40px;
                }
                textarea {
                    border: 1px solid #2B2B2B;
                    border-radius: 10px;
                    resize: none;
                }
            }
        }
        button {
            width: 100%;
            margin-top: 20px;
        }
    }
}

