@import "../../Styles/variables.scss";

.cart-main {
    margin: 50px 0px 10px 0px;
    .border-thick {
      border-right: 5px solid #b7b7b7;
    }
    h2 {
      text-align: left;
      margin-top: 40px;
      font-weight: 600;
      padding-bottom: 25px;
      border-bottom: 2px solid #e2e2e2;
      margin-bottom: 50px;
  }
    .cart-shop-all {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid #707070;
        margin-bottom: 40px;
        &:last-child {
            border-bottom: none;
        }
        .remove-cart {
            display: flex;
            width: 100%;
            .text-center{
              position: relative;
            }
            .cart-img {
                width: 200px;
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border: 1px solid #e2e2e2;
                margin-bottom: 15px;
                img {
                    width: 100%;
                }
            }
            .trash-p {
                color: $color-text;
                font-size: 18px;
                cursor: pointer;
                display: inline;
                position: absolute;
                top: 3px;
                right: 0;
                opacity: 1;
                &:hover{
                  opacity: 0.7;
                }
                i {
                    margin-right: 5px;
                }
            }

            .home-cart-desc {
                margin-left: 15px;
                width: 100%;
                .desc-kit-dollar {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h3 {
                        margin-bottom: 0;
                        font-weight: 700;
                        color: #098180;
                        letter-spacing: 1px;
                    }
                    p {
                        margin-bottom: 0;
                        font-size: 22px;
                        font-weight: 700;
                        color: #098180;
                        letter-spacing: 1px;
                    }
                }
                span {
                    color: #7f7f7f;
                    font-size: 16px;
                }
                .quantity-btn {
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    p {
                      margin-bottom: 0;
                      font-size: 20px;
                      margin-right: 25px;
                    }
                    .quantity-btn-sub {
                      height: 45px;
                      display: flex;
                      flex-direction: row;
                      border: 1px solid #800020;;
                      button {
                        height: 100%;
                        width: 50px;
                        background-color: #fff;
                        border: none;
                        span{
                          .fas{
                            color: #800020;
                          }
                        }
                      }
                      input {
                        height: 100%;
                        border-radius: 0;
                        border-left: 1px solid #800020;
                        border-right: 1px solid #800020;
                        border-top: none;
                        border-bottom: none;
                        text-align: center;
                        width: 60px;
                        line-height: 0;
                        font-size: 15px;
                        background: #8080804a;
                        &:focus {
                          outline: none;
                        }
                      }
                      input::-webkit-outer-spin-button,
                      input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                    }
                  }
            }
        }
    }
}

@media screen and (max-width: 767px) {
  .cart-main {
    margin-top: 15px;

    h2 {
      font-size: 24px;
      margin: 0;
      padding-bottom: 10px;
    }

    .border-thick {
      border-right: 0;
      .cart-shop-all {
        margin-bottom: 0px;
        padding: 7px 0px;
        .remove-cart {
          display: flex;
          width: 100%;

          .text-center {
            position: relative;
          }

          .cart-img {
            width: 120px;
            height: 120px;
          }

          .trash-p {
            font-size: 14px;
          }

          .home-cart-desc {
            .desc-kit-dollar {
              flex-direction: column;
              align-items: flex-start;
              h3 {
               font-size: 16px;
              }

              p {
                font-size: 16px
              }
            }

            .ship{
              font-size: 12px;
            }
            .quantity-btn {
              align-items: flex-start;
              flex-direction: column;
              margin-top: 0px;

              p {
                font-size: 16px;
              }

              .quantity-btn-sub {
                height: 35px;
                button {
                  width: 40px;
                  height: 100%;
                }

                input {
                  width: 50px;
                }
              }
            }
          }
        }
      }

      #removeItem {
        .modal-dialog {
          .modal-content {
            .modal-header {
              .modal-title{
                font-size: 18px;
              }
            }
            .modal-body{
              button{
                padding: 7px 20px;
              }
            }
          }
        }
      }
    }

    .order-main-sum{
      padding: 10px 20px;
      h3{
        font-size: 22px;
      }
    }
  }
}
