.help-main{
  .left-container{
    .search-main {
      display: flex;
      margin-bottom: 0;
      position: relative;
      width: 100%;
      margin-bottom: 35px;
      input {
        border-radius: 0px;
        height: 35px;
        border: none;
        &::placeholder {
          color: #000;
        }
        &.search-query{
          display: block;
          width: 100%;
          margin: 0;
          padding-right: 35px;
          font-size: 14px;
          line-height: 18px;
          height: 40px;
          border: 1px solid #D0D0D0;
          box-sizing: border-box;
          border-radius: 3px;
          color: #666;
        }
      }
      button {
        position: absolute;
        right: 10px;
        top: 10px;
        margin: 0;
        padding: 0;
        line-height: 16px;
        border: 0;
        background: 0 0;
        .fa-search{
          color: #C6C6C6;
        }
      }
      .search-result{
        position: absolute;
        background: #fff;
        border: 1px solid #CCC;
        z-index: 1;
        top: 38px;
        width: 100%;
        border-radius: 0 5px 5px;
        box-shadow: 0 2px 5px #8080806b;
        .result{
          margin: 0;
          max-height: 450px;
          overflow: auto;
          padding: 0;
          li{
            list-style: none;
            text-align: left;
            line-height: 18px;
            &:first-child{
              margin-top: 5px;
            }
            a{
              display: block;
              padding: 7px 15px;
              font-size: 15px;
              color: #4381b5;
              text-decoration: none;
              &:hover{
                color: #006298;
              }
            }
          }
          .noResult{
            font-size: 12px;
            color: #797979;
            padding: 4px 0 4px 14px;
          }
        }
      }
    }
    .category-head{
      text-transform: uppercase;
      font-size: 12px;
      color: #222;
      margin-bottom: 12px;
      font-weight: bold;
    }
    .category-list{
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      overflow: auto;
      li{
        padding: 0;
        a{
          display: inline-block;
          color: #858585;
          font-size: 14px;
          padding: 6px 15px 6px 0;
          line-height: 20px;
          margin-left: 0;
          &.active{
            font-weight: 700;
            color: #222;
            background: 0 0;
            text-shadow: none;
          }
          &:hover{
            text-decoration: none;
            color: #222;
            background: 0 0;
          }
        }
      }
    }
  }
  .right-container{
    border: 1px solid #e9e5e5;
    min-height: 500px;
    .spinner-border{
      position: relative;
      left: 50%;
      top: 25px;
      width: 30px !important;
      height: 30px !important;
    }
    .faq-container{
      padding: 20px 25px;
      h1{
        font-weight: 400;
        font-size: 30px;
      }
      .faq-list{
        padding: 0;
        .faq {
          margin: 1.4em 0;
          display: flex;
          flex-direction: column;
          list-style: none;
          padding: 0;
          .heading{
            .fa-file{
              color: #A7A7A7;
            }
            a{
              padding: 7px 15px;
              font-size: 20px;
              color: #4381b5;
              text-decoration: none;
              &:hover{
                color: #006298;
              }
            }
          }
        }
      }
    }
  }
}
