@import "../../Styles/variables.scss";

.register-kit-main {
    &.width-50{
     width: 50%;
     margin-top: 20px;
    }
    .register-label{
        color: #8e8b8b;
    }
    .kit-id{
        position: relative;
    }
    .error-validation{
        position: absolute;
        left: 0;
        bottom: -18px;
    }
    .tester-detail-form {
        .error-validation {
            position: absolute;
            left: 0;
            bottom: -5px;
        }
        .product-img{
            img{
                width: 90%;
            }
        }
    }
    .section-heading{
        font-weight: 700;
        margin-bottom: 20px;
        word-break: break-word;
        color: #098180;
        letter-spacing: 1px;
    }
    .PhoneInput{
        .PhoneInputCountry{
            display: none;
        }
        .PhoneInputInput{
            margin-bottom: 0;
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            &:focus{
                background-color: #fff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem #80bdff87;
            }
        }
    }
    .welcome-main {
        width: 50%;
        text-align: center;
        margin: 0 auto;
        box-shadow: 0px 5px 30px #00000029;
        padding: 30px 20px;
        span {
            color: #8E8B8B;
        }
        p {
            margin: 10px;
            font-size: 18px;
        }
        form {
            width: 70%;
            margin: 0 auto;
            margin-top: 25px;
            .form-group {
                position: relative;
            input {
                border-radius: 0;
                height: 54px;
             }
            }
            .group-welcome-custom {
                margin-top: 30px;
            label {
                color: #8E8B8B;
                font-size: 18px;
                text-align: left;
                padding-top: 0px;
            }
            }
        }
        .cart-return-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 50px;
            a {
                color: #800020;
                text-decoration: none;
                font-size: 18px;
                cursor: pointer;
                &:hover{
                    color: #800020b0;
                }
                i {
                    color: #800020;
                }
                span {
                    margin-right: 10px;
                    color: #000;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .register-kit-main {
        &.width-50{
            width: 100%;
        }
        h2{
            font-size: 22px;
        }
        h4{
            font-size: 18px;
        }
        li{
            p{
                font-size: 14px;
            }
        }
        .welcome-main {
            margin: 0px;
            width: 100%;
            padding: 15px 5px;
            p{
                &.error-validation{
                    font-size: 14px;
                    left: 0;
                    margin: 0px;
                }
            }
            form {
                width: 100%;
                .form-group {
                    input {
                        height: 40px;
                    }
                }
                .group-welcome-custom {
                    margin-top: 25px;
                    .custom_radio {
                       margin-bottom: 7px;
                    }
                }
            }
            .cart-return-btn {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
