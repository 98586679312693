@import "../../Styles/variables.scss";

.reports-main {
    margin: 40px 0;
    h2 {
        text-align: left;
        margin-top: 40px;
        font-weight: 600;
        padding-bottom: 25px;
        border-bottom: 2px solid #e2e2e2;
      }
    .table {
        thead {
            font-weight: 600;
        }
        td,
        th {
            text-align: center;
        }
        th {
            text-transform: capitalize;
        }
        td {
            vertical-align: text-top;
            padding: 0.75rem;
            a{
                display: inline-block;
                &.download-btn{
                    text-decoration: none;
                    color: #fff;
                    background: #800020;
                    padding: 5px 9px;
                    border-radius: 50%;
                    margin-left: 5px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .reports-main {
        margin: 0;

        h2 {
            margin: 10px 0px;
            padding-bottom: 5px;
            font-size: 24px;
        }

        .table {
            thead {
               tr{
                   th{
                       font-size: 14px;
                       padding: 5px;
                       text-transform: capitalize;
                       text-align: center;
                   }
               }
            }

            tbody {
                tr {
                    td {
                        font-size: 12px;
                        padding: 5px;
                        vertical-align: middle;
                        text-align: center;
                        a{
                            &.red-btn-border{
                                padding: 3px 10px;
                                display: inline;
                            }
                            &.download-btn{
                                padding: 3px 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}
