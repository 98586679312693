@import "../Styles/variables.scss";

@media (max-width: 1200px) and (min-width: 992px) {
//Header
.header-main {
    .header {
        .navbar {
            .navbar-collapse {
                ul {
                    li {
                        margin: 6px;
                    }
                }
            }
        }
    }
}

//Home
.home-all-main {
    .included-test {
        .demo-kit-img {
            &::after {
                right: -20px;
                top: -32px;
            }
        }
    }
}

//Footer
.footer-main {
    .footer {
        .footer-resize {
            width: 70%;
        }
    }
}
}

@media (max-width: 991px) and (min-width: 768px) {

//Header
.header-main {
    .header-sub-main {
        .header-sub-desc {
            .p-group {
                p {
                    font-size: 12px;
                }
            }
        }
    }
    .header {
        .navbar {
            .main-logo {
                width: 150px;
            }
            .navbar-collapse {
                ul {
                    li {
                        margin: 2px;
                        a {
                            font-size: 14px;
                            padding: 2px;
                        }
                    }
                }
            }
        }
    }
}

//breadcrumbs
.breadcrumbs-main {
    ul {
        li {
            font-size: 14px;
        }
    }
}

//Footer

.footer-main {
    .footer {
        .footer-resize {
            width: 100%;
        }
    }
}

//Home
.home-all-main {
    .home-main {
        .test-group {
            .card-work {
                margin-bottom: 70px;
                text-align: center;
                padding-right: 0;
                .card-icon-work {
                    margin: 0 auto;
                    margin-bottom: 20px;
                    &::after {
                        left: -28px;
                    }
                }
            }
        }
    }

    .included-test {
        margin-top: 30px;
        .demo-kit-img {
            width: 400px;
            margin: 0 auto;
            margin-top: 100px;
        }
    }

    .products-main {
        .products-group {
            .products-card {
                margin-bottom: 50px;
            }
        }
    }
}
}

@media screen and (max-width: 767px) {
    //header
    .header-main {
        .header-sub-main {
            .container-fluid{
                padding: 0;
                .header-sub-desc {
                    flex-wrap: wrap;
                    justify-content: center;
                    .p-group {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        p {
                            font-size: 14px;
                        }
                    }
                    .search-main {
                        margin-top: 5px;
                        width: 100%;
                    }
                }
            }

        }

        .header {
            .navbar {
                .main-logo{
                    height: 50px;
                }
                .navbar-collapse {
                    .navbar-nav{
                        display: block;
                    }
                    ul {
                        li {
                            margin: 0;
                            .logo-plus-link{
                                margin-left: 10px;
                            }
                            a {
                                padding: 10px;
                            }
                        }
                    }
                }
                .main-logo {
                    width: 180px;
                    a {
                        margin-right: 0;
                    }
                }
                .navbar-toggler {
                    span {
                        height: auto;
                        font-size: 26px;
                        color: $color-background;
                    }
                }
            }
        }
    }

    //forgot password

    .signup-form {
        margin: 0px;
        width: 100%;
    }

    .breadcrumbs-main{
        margin-top: 150px;
        padding-bottom: 5px;
        .container-fluid{
            ul{
                li{
                    font-size: 12px;
                }
            }
        }
    }

    //footer

    .footer-main {
        .footer {
            padding-top: 10px;
            padding-bottom: 0;
            .footer-resize {
                .footer-list{
                    h2{
                        font-size: 22px;
                        margin-bottom: 5px;
                    }
                    p{
                        line-height: 20px;
                        font-size: 14px;
                    }
                    .footer-icon{
                        margin-bottom: 0;
                        ul{
                            justify-content: end;
                        }
                    }
                    &.links{
                        h2{
                            margin-bottom: 10px;
                        }
                    }
                }
                margin: 0;
                width: 100% !important;
            }
        }
        .footer-sub{
            font-size: 12px;
        }
    }

    //Home

    .home-all-main {
        .home-main {
            .home-bg {
                padding: 115px;
            }
            .slider-well {
                .item-main {
                    padding-top: 65px;
                    .slider-desc {
                        padding: 10px 20px;
                        h2{
                            font-size: 20px;
                        }
                        p{
                            font-size: 16px;
                            line-height: 25px;
                        }
                        width: 100%;
                    }
                }
            }
            .test-group {
                .common-h2 {
                    .heart-rate-h2 {
                        font-size: 20px;
                        &::before {
                            left: 62px;
                            width: 37%;
                            height: 70px;
                            top: -18px;
                        }
                        &::after {
                            right: 62px;
                            width: 37%;
                            height: 70px;
                            top: -18px;
                        }
                    }
                    .heart-rate-description{
                        margin-bottom: 0px;
                    }
                }
                .card-work {
                    padding-right: 0;
                    margin-bottom: 60px;
                }
            }
        }
        .included-test {
            margin-top: 0px;
            .demo-kit-img {
                display: none !important;
                width: 70%;
                margin: 0 auto;
                margin-top: 86px;
            }
        }
        .products-main {
            height: 100%;
            margin-bottom: 25px;
            margin-top: 25px;
            padding: 10px 5px;
            .products-card-text {
                padding: 20px;
                background-color: #fff;
                margin: 0;
                color: #098180;
            }
            .test-btn-h2 {
                h2 {
                    font-size: 20px;
                }
                .white-btn-border {
                    padding: 6px 30px;
                }
            }
        }
        .products-upper{
            .products-group{
                .products-card{
                    margin-bottom: 35px;
                }
            }
        }
    }
}
