@import "../../Styles/variables.scss";

.header-main {
/*   position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2; */
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999999;
  .header-sub-main {
    background-color: $color-background;
    color: #fff;
    padding: 12px;
    .header-sub-desc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .p-group {
        display: flex;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.27px;
        color: rgb(255, 255, 255);
        p {
          margin-right: 10px;
          margin-bottom: 0;
          b {
            cursor: pointer;
          }
        }
      }
      .search-main {
        display: flex;
        margin-bottom: 0;
        position: relative;
        width: 40%;
        input {
          border-radius: 0px;
          height: 35px;
          border: none;
          &::placeholder {
            color: #000;
          }
        }
        button {
          padding: 3px 10px;
          border: none;
          font-size: 18px;
          background-color: $color-text;
          color: #fff;
          outline: none;
        }
        .search-filter-item {
          position: absolute;
          top: 54px;
          left: 0;
          width: 100%;
          z-index: 999;
          background-color: #dedee0c2;
          padding: 10px;
          border-radius: 0;
          border: 1px solid #ddd;
          max-height: 142px;
          overflow-y: scroll;
          a {
            text-decoration: none;
            .search-filter-item-detail {
              background-color: #fff;
              padding: 5px;
              border: 1px solid #ddd;
              margin-bottom: 5px;
              h3 {
                font-size: 14px;
                margin-bottom: 0;
                font-weight: 500;
                color: #707070;
              }
              p {
                font-size: 12px;
                margin-bottom: 0;
                color: #707070;
              }
            }
          }
        }
      }
    }
  }


  #signin-inner,
  #signup-inner,
  #cart-inner,
  #change-inner,
  #edit-inner {
    .modal-dialog {
      .modal-content {
        padding: 12px 30px;
        @media screen and (max-width: 767px) {
          padding: 0px 10px;
        }
        .modal-header {
          margin-bottom: 20px;
          h4 {
            text-transform: uppercase;
            font-weight: 700;
          }
          button {
            outline: none;
          }
        }
        .modal-body {
          height: 80vh;
          overflow-y: auto;
          .signup-form {
            width: 100%;
          }
          .login-desc-modal {
            text-align: center;
            margin-top: 60px;
            border-top: 1px solid #dee2e6;
            padding-top: 30px;
            @media screen and (max-width: 767px) {
              padding: 0;
              margin-top: 15px;
            }
            span {
              color: #cccccc;
              font-size: 50px;
              border: 1px solid;
              border-radius: 50%;
              padding: 0px 16px 7px;
              i{
                font-size: 35px;
              }
            }
            h3 {
              font-size: 26px;
            }
            a {
              font-size: 20px;
              text-decoration: underline;
              color: #000;
              cursor: pointer;
            }
            .create-account, .login{
              color: #098180;
              text-decoration: none;
              letter-spacing: 1px;
              border: 1px solid;
              border-radius: 15px;
              padding: 5px 10px;
            }
          }

          .cart-item {
            display: flex;
            justify-content: space-between;
            .item-img {
              width: 120px;
              height: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              flex-flow: column;
              img {
                width: 100%;
              }
              p {
                color: $color-text;
                margin-top: 10px;
                cursor: pointer;
              }
            }
            .cart-qty {
                label {
                    font-size: 20px;
                }
                h4 {
                    text-align: center;
                    padding: 10px 0;
                    margin-top: 20px;
                    border-top: 1px solid #dbdbdb;
                }
              .quantity-btn-sub {
                height: 45px;
                display: flex;
                flex-direction: row;
                border: 1px solid #800020;;
                button {
                  height: 100%;
                  width: 50px;
                  background-color: #fff;
                  border: none;
                  span{
                    .fas{
                      color: #800020;
                    }
                  }
                }
                input {
                  height: 100%;
                  border-radius: 0;
                  border-left: 1px solid #800020;
                  border-right: 1px solid #800020;
                  border-top: none;
                  border-bottom: none;
                  text-align: center;
                  width: 60px;
                  line-height: 0;
                  font-size: 15px;
                  background: #8080804a;
                  &:focus {
                    outline: none;
                  }
                }
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              }
            }
          }
          .subtotal-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            border-top: 1px solid #b7b7b7;
            p {
                padding: 0 20px;
                margin-top: 5px;
            }
          }
          .cart-btn-group {
            margin-top: 20px;
            display: flex;
            flex-flow: column;
            button {
                margin-bottom: 15px;
            }
          }
        }
      }
    }
    .change-form {
      .form-group {
        position: relative;
        input {
          height: 45px;
          border: 1px solid #2B2B2B;
          border-radius: 10px;
          padding-left: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }
  .header {
    background-color: #f5f5f5;
    padding: 0;
    .navbar {
      .main-logo {
        width: 250px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .navbar-collapse {
        justify-content: flex-end;
        ul {
          align-items: center;
          li {
            margin: 10px;
            display: flex;
            align-items: center;
            a {
              color: #000;
              font-weight: 500;
              &::after {
                display: none;
              }
              &:hover {
                color: #444;
              }
              &.active {
                font-weight: 600;
              }
              .login-user {
                width: 35px;
                height: 35px;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #e4e4e4;
                font-weight: 800;
                cursor: pointer;
              }
            }
            .dropdown-menu {
              padding: 0px 10px;
              box-shadow: 0px 5px 20px #00000029;
              border-radius: 0;
              width: 222px;
              a {
                padding: 10px 0;
                border-bottom: 1px solid #d7d7d7;
                display: flex;
                align-items: center;
                cursor: pointer;
                &:last-child {
                  border-bottom: none;
                }
                span {
                  color: #a8a8a8;
                  margin-right: 10px;
                  font-size: 20px;
                }
              }
            }
            .logo-plus-link {
              display: flex;
              align-items: center;
              cursor: pointer;
              &:hover{
                .logo-p-change {
                  color: #fff;
                  background-color: #800020;
                }
              }
              .logo-p-change {
                margin-bottom: 0;
                padding: 4px 8px;
                font-size: 16px;
                color: #800020;
                border-radius: 50%;
                border: 2px solid #800020;
              }
              a {
                p {
                  margin-bottom: 0;
                  &.card-details{
                    color: #444;
                    font-size: 12px;
                  }
                }
              }
            }
            .user-name-unique {
              margin-bottom: 0;
              font-size: 14px;
              font-weight: 700;
              word-break: break-word;
            }
          }
        }
      }
    }
  }
}
.modal{
  z-index: 99999999 !important;
}
