@import "../../Styles/variables.scss";

.employees-main {
  margin: 40px 0;
  .heading {
    text-align: left;
    font-weight: 600;
    padding-bottom: 8px;
    font-size: 2rem;
  }
  .employee-table-head-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 10px;
    .employee-table-search {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      .form-control {
        border: 1px solid #D1D1D1 !important;
        border-radius: 0px;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
        width: auto;
        height: auto;
        min-width: 300px;
      }
      .add-btn{
        .fa-plus {
          margin-left: 10px;
          padding: 3px 4px;
          font-size: 16px;
          color: #800020;
          border-radius: 50%;
          border: 2px solid #800020;
          cursor: pointer;
          &:hover{
            color: #fff;
            background-color: #800020;
          }
        }
      }
    }
  }
  .table-responsive {
    max-height: 400px;
    .table {
      tbody {
        font-size: 14px;
        th, td{
          padding: 8px;
        }
        tr {
          td {
            .product-test-base {
              display: flex;
              align-items: center;
              .test-base-img {
                width: 110px;
                height: 110px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                img {
                  width: 100%;
                }
              }
              p {
                margin-left: 10px;
                font-size: 18px;
                font-weight: 500;
              }
            }
            .track-order-loc {
              li {
                position: relative;
                line-height: 40px;
                &::after {
                  position: absolute;
                  content: "";
                  background-color: #000;
                  width: 2px;
                  height: 34px;
                  left: -16px;
                  top: -16px;
                }
                &:first-child {
                  &::after {
                    display: none;
                  }
                }
              }
            }
            .active{
              background-color: #cbf4c9;
              color: #0e6245;
              padding: 2px;
            }
            .inactive{
              background-color: #f0d6d0;
              color: #d93110;
              padding: 2px;
            }
          }
        }
      }
    }
    .red-btn-border{
      cursor: pointer;
      padding: 2px 15px;
    }
  }
}

.add-employees-main{
  .container{
    .custom-tab{
      .nav-tabs {
        .nav-item{
          &.active {
            background-color: #098180b3;
          }
          &.nav-link{
            color: #495057;
          }
        }
      }
    }
    margin-top: 15px;
    .form-group {
      position: relative;
      .form-control{
        margin-bottom: 25px;
      }
      input, select{
        height: 35px;
        border: 1px solid #2B2B2B;
        border-radius: 10px;
        padding-left: 20px;
        margin-bottom: 30px;
      }
      .error-validation{
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 25px;
        left: 0;
      }
      .PhoneInput{
        .PhoneInputCountry{
          display: none;
        }
        .PhoneInputInput{
          margin-bottom: 0;
          color: #495057;
          &:focus{
            background-color: #fff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem #80bdff87;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .employees-main {
    margin: 0;
    .heading {
      text-align: left;
      font-weight: 600;
      padding-bottom: 8px;
      font-size: 2rem;
    }
    .employee-table-search {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      .form-control {
        border: 1px solid #D1D1D1 !important;
        border-radius: 0px;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
        width: auto;
        height: auto;
      }
      .add-btn{
        .fa-plus {
          margin-left: 10px;
          padding: 6px 7px;
          font-size: 16px;
          color: #800020;
          border-radius: 50%;
          border: 2px solid #800020;
          cursor: pointer;
          &:hover{
            color: #fff;
            background-color: #800020;
          }
        }
      }
    }
    .table-responsive {
      .table {
        thead{
          tr{
            th{
              font-size: 14px;
              padding: 5px;
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 12px;
              padding: 5px;
              vertical-align: middle;
              text-align: center;
              .product-test-base {
                display: flex;
                flex-direction: column;
                .test-base-img{
                  width: 100px;
                  height: 100px;
                }
                p {
                  font-size: 12px;
                }
              }
              button{
                &.red-btn-border{
                  padding: 3px 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.payment-history{
  thead{
    th{
      padding-left: 5px;
    }
  }
  tbody {
    tr {
      td {
        font-size: 14px;
        padding: 5px;
        vertical-align: middle;
      }
    }
  }
  .red-btn-border{
    padding: 2px 7px;
    font-size: 12px;
  }
}
.small-spin{
  font-size: 12px;
  width: 15px !important;
  height: 15px !important;
}
.payment-detail-container{
  .title{
    font-size: 16px;
    margin-right: 5px;
  }
  .value{
    font-size: 14px;
    color: grey;
    font-weight: bold;
  }
  .card-detail-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    img{
      width: 50%;
    }
    .card-detail{
      .ending{
        font-size: 14px;
        color: #444;
      }
    }
  }
}
