@import "../../Styles/variables.scss";

.footer-main {
  .footer {
    background-color: $color-background;
    color: #fff;
    padding: 50px 0;
    .footer-resize {
      width: 60%;
      margin: 0 auto;
      .footer-list {
        display: flex;
        flex-flow: column;
        h2 {
          font-size: 26px;
          font-weight: 600;
          margin-bottom: 20px;
        }
        ul {
          list-style: none;
          &.border-white{
            border-left: 4px solid #fff;
            padding-left: 10px;
          }

          li {
            cursor: pointer;
            margin-bottom: 10px;
            a {
              color: #fff;
              text-decoration: none;
              &:hover {
                color: #d3d3d3;
              }
            }
          }
        }
        .footer-icon {
          ul {
            list-style: none;
            padding-left: 0;
            display: flex;
            align-items: center;
            font-size: 30px;
            li {
              margin: 0px 10px;
              cursor: pointer;
              &:first-child {
                margin-left: 0;
              }
              i{
                &.fab{
                  font-size: 24px;
                }
              }
            }
          }
        }
        .footer-pay-logo {
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .footer-sub {
    padding: 20px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 2px;
    p {
      margin-bottom: 0;
    }
  }

  #signin-inner,
  #signup-inner,
  #cart-inner,
  #change-inner,
  #edit-inner {
    .modal-dialog {
      .modal-content {
        padding: 12px 30px;
        .modal-header {
          margin-bottom: 20px;
          h4 {
            text-transform: uppercase;
            font-weight: 700;
          }
          button {
            outline: none;
          }
        }
        .modal-body {
          height: 80vh;
          overflow-y: auto;
          .signup-form {
            width: 100%;
          }
          .login-desc-modal {
            text-align: center;
            margin-top: 60px;
            border-top: 1px solid #dee2e6;
            padding-top: 30px;
            span {
              color: #cccccc;
              font-size: 50px;
              border: 1px solid;
              border-radius: 50%;
              padding: 0px 16px 7px;
              i{
                font-size: 35px;
              }
            }
            h3 {
              font-size: 26px;
            }
            a {
              font-size: 20px;
              text-decoration: underline;
              color: #000;
              cursor: pointer;
            }
            .create-account, .login{
              color: #098180;
              text-decoration: none;
              letter-spacing: 1px;
              border: 1px solid;
              border-radius: 15px;
              padding: 5px 10px;
            }
          }

          .cart-item {
            display: flex;
            justify-content: space-between;
            .item-img {
              width: 120px;
              height: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              flex-flow: column;
              img {
                width: 100%;
              }
              p {
                color: $color-text;
                // margin-top: 10px;
                margin-bottom: 0px;
                cursor: pointer;
              }
            }
            .cart-qty {
                label {
                  line-height: normal;
                  font-size: 16px;
                  i{
                    &.fa-trash{
                      color: $color-text;
                      cursor: pointer;
                      opacity: 1;
                      &:hover{
                        opacity: 0.7;
                      }
                    }
                  }
                }
                h4 {
                    text-align: center;
                    padding: 10px 0;
                    margin-top: 20px;
                    border-top: 1px solid #dbdbdb;
                    font-size: 16px;
                }
              .quantity-btn-sub {
                height: 35px;
                display: flex;
                flex-direction: row;
                border: 1px solid #800020;;
                button {
                  height: 100%;
                  width: 35px;
                  background-color: #fff;
                  border: none;
                  span{
                    .fas{
                      color: #800020;
                    }
                  }
                }
                input {
                  height: 100%;
                  border-radius: 0;
                  border-left: 1px solid #800020;
                  border-right: 1px solid #800020;
                  border-top: none;
                  border-bottom: none;
                  text-align: center;
                  width: 60px;
                  line-height: 0;
                  font-size: 15px;
                  background: #8080804a;
                  &:focus {
                    outline: none;
                  }
                }
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              }
            }
          }
          .subtotal-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            border-top: 1px solid #b7b7b7;
            p {
                padding: 0 20px;
                margin-top: 5px;
            }
          }
          .cart-btn-group {
            margin-top: 20px;
            display: flex;
            flex-flow: column;
            button {
                margin-bottom: 15px;
            }
          }
        }
      }
    }
    .change-form {
      .form-group {
        position: relative;
        input {
          height: 45px;
          border: 1px solid #2B2B2B;
          border-radius: 10px;
          padding-left: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .footer-main {
    #cart-inner {
      .modal-dialog {
        .modal-content {
          padding: 0px 10px;
          .modal-header {
            margin-bottom: 0px;
          }

          .modal-body {
            .cart-item {
              p{
                font-size: 14px;
              }

              .cart-qty {
                .quantity-btn-sub {
                  button {
                    width: 40px;
                  }

                  input {
                    width: 50px;
                  }
                }
                h4{
                  &.total{
                    text-align: right;
                  }
                }
              }
            }

            .subtotal-div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 20px;
              border-top: 1px solid #b7b7b7;

              p {
                padding: 0 20px;
                margin-top: 5px;
              }
            }

            .cart-btn-group {
              margin-top: 20px;
              display: flex;
              flex-flow: column;

              button {
                margin-bottom: 15px;
              }
            }
          }
        }
      }

      .change-form {
        .form-group {
          position: relative;

          input {
            height: 45px;
            border: 1px solid #2B2B2B;
            border-radius: 10px;
            padding-left: 20px;
            margin-bottom: 15px;
          }
        }
      }
    }

    #removeItem {
      .modal-dialog {
        .modal-content {
          .modal-header {
            .modal-title{
              font-size: 18px;
            }
          }
          .modal-body{
            button{
              padding: 7px 20px;
            }
          }
        }
      }
    }

    .footer {
      .footer-resize {
        .footer-list {
          ul {
            &.border-white{
              font-size: 12px;
            }
            }
        }
      }
    }

  }
}
