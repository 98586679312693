@import "../../Styles/variables.scss";

.home-product-section {
  padding-bottom: 3rem;
  margin-top: 1.5rem;
}

@media screen and (max-width: 767px) {
  .home-product-section {
    margin-top: 10px;
    .container{
      .container-fluid{
        padding: 0;
        h1{
          font-size: 24px;
          text-transform: capitalize;
        }
        h2{
          font-size: 24px;
        }
        .content{
          font-size: 14px;
          p{
            margin-bottom: 5px;
            text-align: justify;
          }
        }
      }
    }
  }
}
