@import "../../Styles/variables.scss";

.home-all-main {
  .home-main {
    .home-bg {
      background-image: url("../../Images/Accugenedx_website banner_final_3.jpg");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      padding: 240px;
      background-position: center;
      background-size: cover;
    }
    .slider-well {
      position: relative;
      top: -270px;
      .item-main {
        padding-top: 40px;
        .slider-desc {
          @include green-gradient;
          width: 380px;
          padding: 32px 20px 30px;
          color: #fff;
          border-radius: 0px 40px 40px 0px;
          p {
            margin-bottom: 4px;
            line-height: 40px;
          }
          .learn-btn {
            text-align: right;
            a {
              color: #fff;
              display: inline;
              span {
                margin-left: 6px;
              }
            }
          }
        }
        .slider-label {
          background-color: #fff;
          width: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 17px;
          padding: 10px;
          border-radius: 0px 40px 40px 0px;
          box-shadow: 0px 5px 20px #0000004f;
          p {
            margin-bottom: 0;
            font-weight: 600;
          }
        }
      }
      .owl-nav {
        position: absolute;
        bottom: 24px;
        right: 0;
        display: none;
        button {
          background-color: $color-background;
          color: #fff;
          font-size: 50px;
          width: 70px;
          height: 60px;
          position: relative;
          margin: 0;
          border-radius: 0;
          outline: none;
          span {
            position: absolute;
            top: -12px;
            right: 27px;
          }
        }
      }
    }
    .test-group {
      margin-top: -180px;
      .common-h2 {
        margin-bottom: 50px;
        h3 {
          margin-top: 30px;
          margin-bottom: 90px;
        }
      }
      .card-work {
        padding-right: 42px;
        text-align: center;
        .card-icon-work {
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 40px;
          position: relative;
          margin: auto;
          img {
            width: 100%;
          }
          &::after {
            content: "";
            position: absolute;
            background-color: $color-background;
            width: 110px;
            height: 4px;
            bottom: 0;
            left: -25px;
          }
        }
        h4 {
          font-weight: 700;
          margin-bottom: 16px;
          margin-top: 7px;
        }
      }
    }
  }

  .included-test {
    margin-top: 90px;
    .demo-kit-desc {
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      h4 {
        text-transform: uppercase;
        color: $color-background;
        font-weight: 600;
        margin-bottom: 20px;
      }
      h2 {
        font-weight: 600;
        margin-bottom: 20px;
        letter-spacing: 2px;
      }
      p {
        line-height: 30px;
        margin-bottom: 30px;
      }
    }
    .demo-kit-img {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 61%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        right: -40px;
        top: -50px;
        background-size: cover;
        z-index: -1;
      }
      img {
        width: 90%;
      }
    }
  }

  .products-main {
    padding: 80px 0;
    @include green-gradient;
    margin-bottom: -120px;
    margin-top: 100px;
    padding-bottom: 180px;
    .test-btn-h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .white-btn-border{
        &:hover{
          color: #fff;
          background-color: #800020;
          border-color: #800020;
        }
      }
      h2 {
        margin-bottom: 0;
        color: #fff;
      }
    }
  }
}
