@import "../../Styles/variables.scss";

.subscription-main {
  margin: 40px 0;
  h2 {
    text-align: left;
    font-weight: 600;
    padding-bottom: 25px;
    border-bottom: 2px solid #e2e2e2;
  }
  .subscription-desc {
    .package-main {
      margin-top: 35px;
      border-bottom: 1px solid #707070;
      padding-bottom: 40px;
      h4 {
        margin-bottom: 30px;
      }
      .package-desc {
        text-align: center;
        background-color: #fff;
        color: #000;
        border: 1px solid #707070;
        h4 {
          padding: 20px;
          border-bottom: 1px solid #707070;
        }
        p {
          padding: 20px;
          margin-bottom: 0;
        }
        &.active {
          background-color: $color-background;
          color: #fff;
          border: 1px solid #707070;
          h4{
            border-bottom: 1px solid #fff;
          }
        }
      }
    }

    .previous-order {
      .table-responsive {
        .table {
          tbody {
            tr {
              padding-top: 15px;
              td {
                .check-home {
                  display: flex;
                  align-items: center;
                  .pills-desc {
                    display: flex;
                    align-items: flex-start;
                    .pills-img {
                      width: 16px;
                      height: 16px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      img {
                        width: 100%;
                      }
                    }
                    .pills-home {
                      margin-left: 20px;
                      p {
                        margin-bottom: 10px;
                      }
                      .pills-select {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        select {
                          width: 70px;
                          border-radius: 0;
                          margin-right: 10px;
                        }
                      }
                      .cancel-sub {
                        color: $color-text;
                        cursor: pointer;
                      }
                    }
                  }
                }

                .active{
                  background-color: #cbf4c9;
                  color: #0e6245;
                  padding: 2px;
                }

                .cancelled{
                  background-color: #f0d6d0;
                  color: #d93110;
                  padding: 2px;
                }
              }
            }
          }
        }
      }

      .orders-btn-group {
        text-align: right;
        button {
          margin-left: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .subscription-main {
    margin: 10px 0;
    h2 {
      font-size: 24px;
      padding-bottom: 0px;
    }
    .subscription-desc {
      .package-main {
        margin-top: 15px;
        h4 {
          font-size: 20px;
          margin-bottom: 15px;
        }
        .mobile-view{
          padding: 0 15px;
          .div-offer{
            width: 33%;
            .package-desc {
              h4 {
                font-size: 16px;
                padding: 7px 10px;
                margin-bottom: 0;
              }
              p {
                padding: 5px 10px;
                font-size: 12px;
              }
              &.active {
                background-color: $color-background;
                color: #fff;
                border: 1px solid #707070;
                h4{
                  border-bottom: 1px solid #fff;
                }
              }
            }
          }
        }
      }

      .previous-order {
        .table-responsive {
          .table {
            thead{
              tr{
                th{
                  font-size: 14px;
                  padding: 5px;
                }
              }
            }
            tbody {
              tr {
                padding-top: 15px;
                td {
                  font-size: 12px;
                  padding: 5px;
                  vertical-align: middle;
                }
              }
            }
          }
        }

        .orders-btn-group {
          text-align: right;
          button {
            margin-left: 40px;
          }
        }
      }
    }
  }
}
