@import "../../Styles/variables.scss";

.signin-main-all {
    .sigin-desc {
      h2 {
        text-align: left;
        margin-top: 40px;
        font-weight: 600;
        padding-bottom: 25px;
        border-bottom: 2px solid #e2e2e2;
        @media screen and (max-width: 767px) {
          margin-top: 15px;
          font-size: 24px;
          padding-bottom: 10px;
          margin-bottom: 0;
        }
      }
    }

    .cutomer-details {
      margin-top: 50px;
      position: relative;
      padding-bottom: 50px;
      @media screen and (max-width: 767px) {
        margin-top: 30px;
      }
      .form-toggle {
        position: relative;
        .toggle-button {
          border: 1px solid $color-background;
          display: flex;
          width: 300px;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          border-radius: 50px;
          margin-bottom: 40px;
          p {
            margin-bottom: 0;
            width: 100%;
            text-align: center;
            padding: 15px 20px;
            font-size: 18px;
            text-transform: uppercase;
            cursor: pointer;
            color: $color-background;
            border-radius: 50px;
            &.active {
              @include green-gradient;
              color: #fff;
            }
          }
        }
      }
    }
  }
