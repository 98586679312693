@import "../../Styles/variables.scss";

.payments-main {
    margin-bottom: 80px;
    h2 {
        text-align: left;
        margin-top: 40px;
        font-weight: 600;
        padding-bottom: 25px;
        border-bottom: 2px solid #e2e2e2;
    }
    .signin-process {
        margin-bottom: 60px;
    }
    .box-contact-change {
        border: 1px solid #707070;
        padding: 10px 20px;
        .contact-orlando {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0;
            border-bottom: 1px solid #707070;
            .row-detail{
                display: flex;
                align-items: center;
                flex: 0 0 auto;
                width: 90%;
                span {
                    color: #000;
                    padding-right: 5px;
                    &:first-child{
                        width: auto;
                        font-weight: bold;
                    }
                    overflow: hidden;
                    max-width: 85%;
                }
            }
            .div-btn{
                .red-btn-border{
                    padding: 2px 5px;
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .payment-method {
        margin-top: 40px;
        p {
            border-bottom: 1px solid #707070;
            padding-bottom: 15px;
        }
        img{
            width: 8%;
        }
        .employee-payment-container{
            label{
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
            }
            p{
                font-size: 14px;
                color: #444;
            }
        }
        .stripe-payment {
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
                width: 100%;
            }
        }
    }
}

.change-form {
    .form-group {
        position: relative;
        input {
            height: 45px;
            border: 1px solid #2B2B2B;
            border-radius: 10px;
            padding-left: 20px;
            margin-bottom: 15px;
        }
        textarea{
            border: 1px solid #2b2b2b;
            border-radius: 10px;
            padding-left: 15px;
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 767px) {
    .payments-main {
        margin-bottom: 20px;
        h2 {
            margin-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 0px;
            font-size: 24px;
        }
        .signin-process {
            margin-top: 30px;
            margin-bottom: 0px;
        }
        .box-contact-change {
            margin-top: 20px;
            padding: 10px;
            .contact-orlando {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    padding-bottom: 3px;
                }
                .row-detail{
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    width: 100%;
                    span {
                        max-width: 100%;
                    }
                }
                .div-btn{
                    margin-top: 5px;
                    .red-btn-border{
                        padding: 4px 15px;
                    }
                }
            }
        }
        .payment-method {
            margin-top: 20px;
            h4{
                margin-bottom: 0;
            }
            p {
                padding-bottom: 10px;
            }
            #payment-form{
                .red-btn{
                    margin: 20px 0px !important;
                    width: 100%;
                    padding: 5px 15px;
                }
            }
        }
        .order-main-sum{
            padding: 10px 20px;
            h3{
                font-size: 22px;
            }
        }
    }

    #edit-inner {
        .modal-dialog {
            .modal-content {
                 .modal-header{
                    h4{
                        font-size: 22px;
                    }
                 }
                .modal-body{
                    padding-top: 0;
                    .change-form{
                        label{
                            margin-bottom: 0;
                        }
                        p{
                            &.error-validation{
                                left: 3px;
                                bottom: -20px;
                                font-size: 12px;
                                &.zip-code{
                                    bottom: 5px;
                                    left: 15px;
                                }
                            }
                        }
                        input{
                            height: 35px;
                            font-size: 14px;
                            border-radius: 5px;
                            padding-left: 10px;
                            margin-bottom: 25px;
                        }
                        .red-btn{
                            margin: 10px 0px !important;
                            width: 100%;
                            padding: 5px 15px;
                        }
                    }
                }
            }
        }
    }
}
