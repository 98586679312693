//font color
$color-text: #800020;

//background color
$color-background: #098180;

//background-main

@mixin green-gradient {
    background: transparent linear-gradient(180deg, #00CBC9 0%, #168987 100%);
}

@mixin red-gradient {
    background: transparent linear-gradient(0deg, #A41E37 0%, #D84560 100%);
}

$main-font-weight: 600;

$color-primary: #250c6d;
$color-primary-fade: #68649c;
$color-black: #212121;
$color-white: #fff;
$color-main-button: #fe8264;

$color-text-fade: #68649c;