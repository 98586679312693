@import "../../Styles/variables.scss";

.verify {
    margin: 80px 0;
    position: relative;
    form {
        text-align: center;
        width: 50%;
        margin: 0 auto;
        border: 1px solid #8080806b;
        padding: 20px 0;
        box-shadow: 0px 5px 20px #0000004f;
        h2 {
            margin-bottom: 20px;
        }
        input {
            width: 100%;
            border: none;
            border-bottom: 1px solid;
            padding-bottom: 10px;
            outline: none;
            margin-top: 30px;
        }
        .verify-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;
            a {
                color: $color-text;
            }
        }
    }
    .verify-ul {
        margin-top: 80px;
        ul {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-left: 0;
            list-style: none;
            li {
                border-right: 1px solid;
                padding: 0px 40px;
                cursor: pointer;
                &:last-child {
                    border-right: none;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .verify {
        margin: 30px 10px;
        .container{
            padding: 0;
            form{
                width: 100%;
                h2{
                    font-size: 20px;
                }
            }
        }
        form {
            text-align: center;
            width: 50%;
            margin: 0 auto;
            border: 1px solid #8080806b;
            padding: 20px 0;
            box-shadow: 0px 5px 20px #0000004f;
            h2 {
                margin-bottom: 20px;
            }
            input {
                width: 100%;
                border: none;
                border-bottom: 1px solid;
                padding-bottom: 10px;
                outline: none;
                margin-top: 30px;
            }
            .verify-btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 30px;
                a {
                    color: $color-text;
                }
            }
        }
        .verify-ul {
            margin-top: 80px;
            ul {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-left: 0;
                list-style: none;
                li {
                    border-right: 1px solid;
                    padding: 0px 40px;
                    cursor: pointer;
                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
    }
}
