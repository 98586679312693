@import "../../Styles/variables.scss";

.product-detail-main {
  margin-top: 80px;
  .product-detail-section {
    .coupon-code{
      color: #444;
      background-color: #c3e6cb;
      text-align: center;
      padding: 5px 0;
      margin-bottom: 10px;
      span{
        font-weight: bold;
      }
    }
    .testkit-main {
      .testkit-img {
        img {
          width: 100%;
        }
      }
      p {
        margin-top: 30px;
        line-height: 30px;
      }
    }

    .testkit-main-desc {
      padding: 0 15px;
      .testkit-btn-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        h2 {
          font-weight: 700;
          margin-bottom: 0;
          color: #098180;
          letter-spacing: 1px;
        }
        button {
          border: 1px solid;
          background-color: #fff;
          padding: 10px 20px;
        }
      }
      .testkit-method {
        h3 {
          font-weight: 700;
          border-bottom: 1px solid #b7b7b7;
          padding-bottom: 8px;
          margin-bottom: 25px;
        }
        h4 {
          font-weight: 100;
          font-family: auto;
        }
        .testkit-radio {
          padding-top: 35px;
          border-top: 1px solid #b7b7b7;
        }

        .subscribe-month {
          padding: 20px 15px;
          border: 1px dashed #0b8180;
          .custom_radio {
            display: block;
            position: relative;
            padding-left: 30px;
            margin-bottom: 15px;
            cursor: pointer;
            font-size: 1rem;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            p {
              font-size: 18px;
              &.offer{
                max-width: 120px;
                border: 2px dashed #0b8180;
                padding: 0px 8px;
                margin-left: 5px;
                text-align: center;
                color: #0b8180;
              }
            }
            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              &:checked {
                ~ {
                  .checkmark {
                    background-color: #fff;
                    &:after {
                      display: block;
                    }
                  }
                }
              }
            }
            &:hover {
              input {
                ~ {
                  .checkmark {
                    background-color: #fff;
                  }
                }
              }
            }
            .checkmark {
              margin-top: 3px;
              &:after {
                top: 1.35px;
                left: 1.6px;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: $color-background;
              }
            }
          }
          .checkmark {
            position: absolute;
            top: 14px;
            left: 0;
            height: 20px;
            width: 20px;
            border: 1px solid #707070;
            background-color: #fff;
            border-radius: 50%;
            &:after {
              content: "";
              position: absolute;
              display: none;
            }
          }
        }

        .quantity-btn {
          margin-top: 45px;
          label {
            margin-bottom: 0;
            font-size: 20px;
            margin-right: 25px;
          }
          .button-quantity {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0px;
            .red-btn {
              span {
                margin-right: 10px;
              }
            }
            .quantity-btn-sub {
              height: 45px;
              display: flex;
              flex-direction: row;
              border: 1px solid #800020;;
              button {
                height: 100%;
                width: 50px;
                background-color: #fff;
                border: none;
                span{
                  .fas{
                    color: #800020;
                  }
                }
              }
              input {
                height: 100%;
                border-radius: 0;
                border-left: 1px solid #800020;
                border-right: 1px solid #800020;
                border-top: none;
                border-bottom: none;
                text-align: center;
                width: 60px;
                line-height: 0;
                font-size: 15px;
                background: #8080804a;
                &:focus {
                  outline: none;
                }
              }
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
        }
      }
    }

    .border-thick{
      border-right: 5px solid #b7b7b7;
    }
  }

  .product-tabination-lorem {
    margin-top: 80px;
    .tabination-ul-ip {
      ul {
        display: flex;
        justify-content: center;
        padding-left: 0;
        list-style: none;
        border-bottom: 1px solid #b7b7b7;
        padding-bottom: 18px;
        li {
          font-size: 20px;
          cursor: pointer;
          border-right: 1px solid #b7b7b7;
          padding: 0px 40px;
          text-align: center;
          &.active {
            color: $color-background;
            font-weight: 600;
          }
        }
      }
      .product-desc-tab {
        line-height: 30px;
        margin-top: 25px;
      }
    }
  }

  .video-how-take {
    background-color: #f5f5f5;
    padding: 50px 0;
    &.iframe{
      padding: 27% 0;
    }
    .video-how-desc {
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      text-align: center;
      .video-how-img {
        height: 100px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        display: flex;
        width: 100px;
        &.iframe{
          width: 100%;
          flex-direction: column;
          .video-frame{
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;
            .responsive-iframe{
              position: absolute;
              top: 30%;
              left: 20%;
              bottom: 0;
              right: 0;
              width: 60%;
              height: 60%;
            }
          }
        }
        img {
          width: 100%;
        }
      }
      p {
        width: 50%;
        margin: 0 auto;
      }
    }
    .how-test-video {
      width: 400px;
      position: relative;
      z-index: 1;
      &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 210px;
        right: -100px;
        content: "";
        top: -96px;
        background-color: #daecec;
        z-index: -1;
      }
      video {
        width: 100%;
      }
    }
  }

  .works-main {
    margin-top: 80px;
    .common-h2 {
      margin-bottom: 80px;
    }
    .works-desc {
      text-align: center;
      margin-bottom: 60px;
      .works-img {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
      }
    }
  }

  .products-main {
    padding: 80px 0;
    background: transparent linear-gradient(180deg, #00CBC9 0%, #168987 100%);
    margin-bottom: -120px;
    margin-top: 100px;
    padding-bottom: 180px;
    .test-btn-h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .white-btn-border{
        &:hover{
          color: #fff;
          background-color: #800020;
          border-color: #800020;
        }
      }
      h2 {
        margin-bottom: 0;
        color: #fff;
      }
    }
    .products-group {
      margin-top: 70px;
    }
  }

  .ques-accordion {
    margin-bottom: 80px;
    .ques-desc-option {
      .ques-img {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .still {
        padding: 20px 60px;
        p {
          a {
            color: #000;
            text-decoration: underline;
          }
        }
      }
    }
    #accordion {
      .card {
        border: 0;
        .card-header {
          padding: 20px 0px;
          background-color: transparent;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          p {
            margin-bottom: 0;
          }
        }
        .card-body {
          padding: 20px 0px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .product-detail-main {
    margin-top: 20px;

    .product-detail-section {
      .testkit-main {
        font-size: 14px;
        p {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .testkit-main-desc {
        padding: 0px;
        margin-top: 15px;
        .testkit-btn-group {
          align-items: flex-start;
          margin-bottom: 10px;
          h2 {
            font-size: 24px;
          }
          button {
            border: 1px solid;
            background-color: #fff;
            padding: 10px 20px;
          }
        }
        .testkit-method {
          h3 {
            font-size: 20px;
            margin-bottom: 10px;
          }
          h4 {
            font-size: 18px;
            margin-bottom: 0px;
          }
          p{
            font-size: 14px;
          }
          .testkit-radio {
            padding-top: 10px;
            .custom_radio{
              font-size: 14px;
            }
          }

          .subscribe-month {
            padding: 10px 7px;
            .custom_radio {
              font-size: 14px;
              p {
                font-size: 14px;
                &.offer{
                  max-width: 100px;
                }
              }
              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                &:checked {
                  ~ {
                    .checkmark {
                      background-color: #fff;
                      &:after {
                        display: block;
                      }
                    }
                  }
                }
              }
              &:hover {
                input {
                  ~ {
                    .checkmark {
                      background-color: #fff;
                    }
                  }
                }
              }
              .checkmark {
                margin-top: 3px;
                &:after {
                  top: 1.35px;
                  left: 1.6px;
                  width: 15px;
                  height: 15px;
                  border-radius: 50%;
                  background: $color-background;
                }
              }
            }
            .checkmark {
              position: absolute;
              top: 14px;
              left: 0;
              height: 20px;
              width: 20px;
              border: 1px solid #707070;
              background-color: #fff;
              border-radius: 50%;
              &:after {
                content: "";
                position: absolute;
                display: none;
              }
            }
          }

          .quantity-btn {
            margin-top: 15px;
            label {
              font-size: 16px;
            }
            .button-quantity {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 0px;
              .red-btn-border {
                padding: 7px 12px;
              }
              .quantity-btn-sub {
                height: 35px;
                button {
                  width: 40px;
                }
                input {
                  width: 50px;
                }
              }
            }
          }
        }
      }

    }

    .product-tabination-lorem {
      margin-top: 25px;
      .tabination-ul-ip {
        ul {
          padding-bottom: 0px;
          overflow: auto;
          width: auto;
          display: flex;
          justify-content: space-between;
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
          li {
            font-size: 16px;
            padding: 0px 2px;
            min-width: 150px;
          }
          li:last-child {
            border-right: 0;
          }
        }
        .product-desc-tab {
          font-size: 14px;
          line-height: 20px;
          li{
            font-size: 14px;
          }
        }
      }
    }

    .video-how-take {
      padding: 25px 0;
      &.iframe{
        padding: 27% 0;
      }
      .video-how-desc {
        .video-how-img {
          &.iframe{
            width: 100%;
            flex-direction: column;
            .video-frame{
              position: relative;
              overflow: hidden;
              width: 100%;
              padding-top: 56.25%;
              .responsive-iframe{
                position: absolute;
                top: 30%;
                left: 20%;
                bottom: 0;
                right: 0;
                width: 60%;
                height: 60%;
              }
            }
          }
          img {
            width: 100%;
          }
        }
        h4{
          font-size: 20px;
        }
        p {
          font-size: 14px;
          width: 100%;
        }
      }
    }

    .works-main {
      margin-top: 25px;
      .common-h2 {
        margin-bottom: 10px;
        .heart-rate-h2 {
          font-size: 20px;
          &::before {
            left: 62px;
            width: 45%;
            height: 70px;
            top: -18px;
          }
          &::after {
            right: 62px;
            width: 45%;
            height: 70px;
            top: -18px;
          }
        }
      }
      .works-desc {
        margin-bottom: 0;
        p{
          font-size: 14px;
        }
        .works-img {
          margin-bottom: 10px;
          img {
            width: 80%;
          }
        }
      }
    }

    .products-main {
      margin-bottom: 25px;
      margin-top: 25px;
      padding: 10px 5px;
      .test-btn-h2 {
        .white-btn-border{
          padding: 6px 30px;
        }
        h2 {
          font-size: 22px;
        }
      }
    }

    .products-upper{
      .products-group {
        .products-card{
          margin-bottom: 35px;
        }
      }
    }
  }
}
