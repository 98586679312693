@import "../../Styles/variables.scss";

.categories-main {
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    margin-top: 15px;
  }
  h2 {
    text-align: left;
    font-weight: 600;
    margin-bottom: 45px;
    @media screen and (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }

  .products-category-search {
    .category-card {
      .category-tabination {
        margin-bottom: 30px;
        color: #098180;
        ul {
          padding-left: 0;
          list-style: none;
          display: flex;
          justify-content: space-between;
          border-bottom: 2px solid #e2e2e2;
          padding-bottom: 15px;
          overflow: hidden;
          overflow-x: scroll;
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
          li {
            font-size: 20px;
            cursor: pointer;
            text-align: center;
            white-space: nowrap;
            margin-right: 32px;
            &.active {
              font-weight: 600;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 5px;
                bottom: -17px;
                left: 0;
                background-color: $color-background;
              }
            }
          }
        }
      }
        .card-desc-category {
            text-align: center;
            margin-bottom: 80px;
            .card-cate-img {
            img {
                width: 100%;
                margin-bottom: 20px;
            }
            }
            h4 {
                font-weight: 700;
            }
            p {
                border: 1px solid #707070;
                padding: 10px 20px;
                font-weight: 700;
                margin-top: 20px;
            }
            button {
                width: 100%;
                box-shadow: 0px 10px 20px #00000029;
            }
        }
    }
    .search-category{
      margin-bottom: 20px;
      .input-group-text{
        background-color: #800020;
        color: #fff;
      }
    }
    .products-card{
      @media screen and (max-width: 767px) {
        margin-bottom: 35px;
      }
    }
  }
}
