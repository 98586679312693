@import "../../Styles/variables.scss";

.settings-main {
  margin: 70px 0;
  h2 {
    text-align: center;
    font-weight: 600;
    margin-bottom: 50px;
  }
  .hi-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    h3 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      color: #203c69;
      font-size: 22px;
      cursor: pointer;
      span {
        margin-right: 10px;
      }
    }
  }
  form {
    padding: 20px;
    box-shadow: 0px 5px 20px #00000029;
    .form-group {
      label {
        font-size: 20px;
      }
      input {
        height: 50px;
        border-radius: 0;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
