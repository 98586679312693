@import "../../Styles/variables.scss";


.confirm-img-resize{
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid;
    margin: 0 auto;
    margin-right: 20px;
    margin-left: 0px;
    img {
        width: 100%;
    }
    
}