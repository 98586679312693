#get-started {
  .container {
    #search-row {
      #search-column {
        margin: 0 auto;
        display: contents;
        #search-box {
          margin-top: 100px;
          margin-bottom: 100px;
          max-width: 400px;
          border: 1px solid #9C9C9C;
          background-color: #EAEAEA;
          #search-form {
            .logo{
              text-align: center;
              margin-bottom: 18px;
              img{
                width: 70%;
              }
            }
            h3{
              font-size: 22px;
              text-transform: uppercase;
            }
            .form-group{
              label{
                margin: 0;
              }
              .help-text{
                font-size: 12px;
                color: #444;
              }
              .info-text{
                color: #444;
                font-size: 14px;
              }
              .status-link{
                color: #098180;
                font-size: 16px;
                cursor: pointer;
                &:hover{
                  color: #098180cc;
                }
              }
              .error-text{
                font-size: 12px;
                color: red;
              }
              .red-btn{
                padding: 12px 40px;
                border: none;
                color: #fff;
                box-shadow: 0px 10px 20px #00000029;
                border-radius: 50px;
                background-color: #800020;
                cursor: pointer;
                &:focus {
                  outline: none;
                }
              }
            }
            .text-info{
              color: #098180 !important;
            }
            padding: 20px;
          }
        }
      }
    }

  }
}

#assessment-container {
  .container {
    input[type='checkbox'] {
      cursor: pointer;
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }
    [data-hasError=true] {
      color: #bd362f;
      span{
        color: #bd362f;
      }
    }
    #assessment-row {
      width: 75%;
      margin: 0 auto;
      #assessment-box {
        #assessment-form {
          margin-top: 50px;
          margin-bottom: 50px;
          .text-info{
            color: #098180 !important;
          }
          .section{
            margin-bottom: 40px;
            h5{
              margin-top: 25px;
              font-weight: bold;
            }
            .section-content{
              border: 1px solid #ccc;
              background-color: #fff;
              padding: 25px;
              .form-group{
                label{
                  margin: 0;
                  font-size: 16px;
                }
                .info-text{
                  color: #444;
                  font-size: 14px;
                }
                .error-text{
                  font-size: 12px;
                  color: red;
                }
                .radio-inline{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  cursor: pointer;
                  color: #444;
                  input[type='radio'] {
                    cursor: pointer;
                    box-sizing: border-box;
                    height: 28px;
                    width: 28px;
                    border-radius: 28px;
                  }
                }
                &.checklist{
                  padding-left: 20px;
                  span{
                    margin-left: 15px;
                  }
                }
                input[type='checkbox'] {
                  cursor: pointer;
                  height: 25px;
                  width: 25px;
                  margin-right: 10px;
                }
              }
            }
          }
          .red-btn{
            padding: 12px 40px;
            border: none;
            color: #fff;
            box-shadow: 0px 10px 20px #00000029;
            border-radius: 50px;
            background-color: #800020;
            cursor: pointer;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}

#scheduler-container {
  width: 60%;
  margin: 0 auto;
  .loader-container{
    position: absolute;
    top: 30%;
    z-index: 99;
    left: 50%;
    align-items: center;
    display: flex;
    color: #444;
    .spinner-border{
      font-size: 10px;
      width: 50px !important;
      height: 50px !important;
    }
  }
  #scheduler-row {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .filter{
      .error-text{
        font-size: 12px;
        color: red;
      }
      .help-text{
        font-size: 12px;
        color: #444;
      }
      .text-info{
        color: #098180 !important;
        margin: 0;
      }
      input{
        &:focus{
          border-color: #6c757d;
          box-shadow: none;
        }
      }
      button{
        &:focus{
          border-color: #6c757d;
          box-shadow: none;
        }
      }
    }
    .scheduler-switch{
      .radio-inline{
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        color: #444;
        input[type='radio'] {
          cursor: pointer;
          box-sizing: border-box;
          height: 28px;
          width: 28px;
          border-radius: 28px;
        }
      }
    }
    .scheduler-info {
      margin-top: 20px;
      .appointment-dates{
          display: flex;
          flex-direction: column;
        .appointment-date{
          box-sizing: border-box;
          min-height: 48px;
          height: auto;
          border: 1px solid #898989;
          border-radius: 12px;
          text-align: center;
          font-size: 14px!important;
          padding: 6px 1px!important;
          color: #333;
          width: 50px;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          box-shadow: 0 1px 3px 0 grey;
          &.active{
            color: #098180;
            border: 2px solid #098180;
            font-size: 14px;
            box-shadow: 0 0 0 1px #098180;
          }
        }
      }
    }
    .scheduler-box {
      margin: 30px 0px;
      .location-container{
        padding: 15px 15px 20px;
        border: solid #bbb;
        border-width: 1px 1px 3px;
        width: 50%;
        margin-bottom: 10px;
        .location-header{
          color: #444;
          line-height: 18px;
          h4{
            font-weight: 700;
            display: inline-block;
            font-size: 16px;
            margin: 0;
          }
          p{
            font-size: 16px;
            margin: 0;
          }
        }
        .additional-info{
          p{
            margin-bottom: 4px;
            font-size: 14px;
            display: flex;
            align-items: center;
          }
        }
        .description{
          text-align: justify;
          background-color: #e2e3e5b3;
          padding: 5px 10px;
          p{
            font-size: 12px;
          }
        }
        .slots{
          overflow-x: auto;
          .red-btn-border {
            background-color: transparent;
            border: 2px solid #800020 !important;
            padding: 5px 12px;
            color: #800020;
            border-radius: 50px !important;
            text-decoration: none !important;
            &:focus {
              outline: none;
              box-shadow: none;
            }
            &:hover {
              color: #fff;
              background-color: #800020;
            }
          }
          .appointment-times{
            .appointment-time{
              box-sizing: border-box;
              height: auto;
              border: 1px solid #898989;
              border-radius: 5px;
              text-align: center;
              font-size: 12px!important;
              padding: 6px 1px!important;
              color: #333;
              margin-right: 10px;
              cursor: pointer;
              box-shadow: 0 1px 3px 0 grey;
              display: flex;
              flex-direction: column;
              min-width: 80px;
              margin: 15px 10px 15px 0px;
              font-weight: bold;
              &.active{
                color: #098180;
                border: 2px solid #098180;
                font-size: 14px;
                box-shadow: 0 0 0 1px #098180;
              }
            }
          }
        }
      }
    }
  }
}

#visit-container {
  width: 50%;
  margin: 0 auto;
  .loader-container{
    position: absolute;
    top: 30%;
    z-index: 99;
    left: 50%;
    align-items: center;
    display: flex;
    color: #444;
    .spinner-border{
      font-size: 10px;
      width: 50px !important;
      height: 50px !important;
    }
  }
  #visit-row {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    hr{
      border: 1px solid #bbb;
      background-color: #bbb;
      width: 100%;
      margin: 5px 0px;
    }
    .visit-information {
      margin-bottom: 10px;
      span{
        font-size: 14px;
      }
    }
    .visit-info {
      .alert-secondary{
        padding: 10px;
        width: 60%;
        margin-bottom: 0;
      }
      ul{
        li{
          font-size: 14px;
        }
      }
    }
    .visit-form {
      .section{
        margin-bottom: 40px;
        h5{
          margin-top: 25px;
          font-weight: bold;
        }
        .info-text{
          color: #444;
          font-size: 14px;
        }
        .section-content{
          border: 1px solid #ccc;
          background-color: #fff;
          padding: 25px;
          .form-group{
            label{
              margin: 0;
              font-size: 16px;
            }
            .error-text{
              font-size: 12px;
              color: red;
            }
            .radio-inline{
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
              color: #444;
              input[type='radio'] {
                cursor: pointer;
                box-sizing: border-box;
                height: 28px;
                width: 28px;
                border-radius: 28px;
              }
            }
            &.checklist{
              padding-left: 20px;
              span{
                margin-left: 15px;
                font-size: 14px;
              }
            }
            input[type='checkbox'] {
              cursor: pointer;
              height: 25px;
              width: 25px;
              margin-right: 10px;
            }
          }
          input[type='text'] {
            border: 1px solid #444;
            &:focus {
              outline: none;
              box-shadow: none;
              border: 2px solid #444;
            }
          }
          select {
            border: 1px solid #444;
            &:focus {
              outline: none;
              box-shadow: none;
              border: 2px solid #444;
            }
          }
          .flex-basis {
            div{
              flex-basis: 35%;
              flex-grow: 0;
            }
          }
        }
        .PhoneInput{
          .PhoneInputCountry{
            display: none;
          }
          .PhoneInputInput{
            margin-bottom: 0;
            border: 1px solid #444;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            border-radius: 0.25rem;
            &:focus{
              background-color: #fff;
              outline: 0;
              box-shadow: none;
              border: 2px solid #444;
            }
          }
        }
        .insurance-upload-container{
          display: flex;
          flex-direction: row;
          margin: 0 auto;
          width: 60%;
          justify-content: space-between;
          .insurance-card {
            min-height: 130px;
            min-width: 175px;
            max-width: 100%;
            text-align: center;
            font-size: 14px;
            color: #444;
          }
          .insurance-front{
            background: url('../../Images/insurance_background_front.svg') no-repeat;
          }
          .insurance-back{
            background: url('../../Images/insurance_background_back.svg') no-repeat;
          }
          .secondary-outline-btn{
            border: 1px solid #6c757d;
            color: #6c757d;
            background-color: transparent;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            padding: 6px 20px;
            min-width: 150px;
            &:hover, &:focus{
              background-color: #6c757d;
              color: #fff;
            }
          }
          .upload-btn{
            .spinner-border{
              width: 14px !important;
              height: 14px !important;
              font-size: 10px;
            }
          }
        }
      }
      .or-block{
        position: relative;
        span{
          display: block;
          position: absolute;
          top: -15px;
          left: 47%;
          background: #fff;
          padding: 5px 10px;
          font-weight: bold;
        }
      }
      .red-btn{
        padding: 12px 40px;
        border: none;
        color: #fff;
        box-shadow: 0px 10px 20px #00000029;
        border-radius: 50px;
        background-color: #800020;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
    .alert{
      &.alert-info{
        width: 60% !important;
      }
    }
  }
  [data-hasError=true] {
    color: #bd362f !important;
    span{
      color: #bd362f !important;
    }
    .form-control {
      border: 1px solid #bd362f !important;
    }
    .PhoneInputInput {
      border: 1px solid #bd362f !important;
    }
    p{
      color: #bd362f !important;
    }
  }
  .red-btn-border {
    background-color: transparent;
    border: 1px solid #800020 !important;
    padding: 5px 7px;
    text-transform: uppercase;
    color: #800020;
    border-radius: 0px !important;
    font-size: 12px;
    width: 100%;
    text-decoration: none !important;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      color: #fff;
      background-color: #800020;
    }
  }
}

#confirmation-container {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 30px;
  .loader-container{
    position: absolute;
    top: 30%;
    z-index: 99;
    left: 50%;
    align-items: center;
    display: flex;
    color: #444;
    .spinner-border{
      font-size: 10px;
      width: 50px !important;
      height: 50px !important;
    }
  }
  #confirmation-row {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .section{
    .location-container{
      padding: 15px 15px 10px;
      border: solid #bbb;
      border-width: 1px 1px 3px;
      width: 80%;
      margin: 0 auto;
      .additional-info{
        p {
          margin-bottom: 4px;
          font-size: 14px;
          display: flex;
          align-items: center;
        }
      }
      hr {
        border: 1px solid #bbb;
        background-color: #bbb;
        width: 100%;
        margin: 15px 0px;
      }
      .visit-information {
        margin-bottom: 20px;
        span {
          font-size: 16px;
        }
      }
      .confirmation-code{
        h4{
          margin: 0;
        }
        .info-text{
          color: #444;
          font-size: 14px;
        }
        .code{
          width: 30%;
          margin: 0 auto;
          border: 2px dashed #098180;
          text-align: center;
          padding: 10px 5px;
          font-size: 20px;
          color: #098180;
        }
      }
    }
  }
  .red-btn-border {
    background-color: transparent;
    border: 2px solid #800020 !important;
    padding: 5px 12px;
    color: #800020;
    border-radius: 50px !important;
    text-decoration: none !important;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      color: #fff;
      background-color: #800020;
    }
  }
}

.stripe-payment{
  img {
    width: 8%;
  }
  .stripe-container {
    #payment-form{
      form{
        margin: 0;
        display: flex;
        align-items: center;
        #card-element{
          width: 88%;
          padding: 10px 0px 2px 0px;
        }
        #submit {
          width: 20%;
          margin-top: 5px !important;
          padding: 0;
          &[disabled] {
            background-color: #800020c2;
          }
          .spinner-border {
            font-size: 12px;
            width: 15px !important;
            height: 15px !important;
          }
        }
      }
      .card-error{
        color: red;
      }
    }
  }
}

#bookings-container {
  width: 75%;
  margin: 0 auto;
  #booking-row{
    margin-top: 50px;
    .table{
      font-size: 14px;
    }
    .confirmation {
      span {
        background-color: #0b818061;
        color: #0b8180;
        padding: 2px;
      }
    }
    .orange{
      color: #098180;
      cursor: pointer;
      border: none;
      background: none;
    }
    .secondary-outline-btn{
      min-width: auto;
      font-size: 12px;
      text-transform: capitalize;
      padding: 6px 15px;
      border: 1px solid #E25E52;
      color: #E25E52;
      background-color: transparent;
      font-weight: 500;
      cursor: pointer;
      &:hover{
        background-color: #E25E52;
        color: #fff;
      }
      .spinner-border {
        font-size: 12px;
        width: 14px !important;
        height: 14px !important;
      }
    }
    .generated {
      background-color: #cbf4c9;
      color: #0e6245;
      padding: 2px;
    }
    .pending {
      background-color: #6c757d61;
      color: #6c757d;
      padding: 2px;
    }
    .cancelled {
      background-color: #e25e524f;
      color: #E25E52;
      padding: 2px;
    }
    .in-progress {
      background-color: #007bff59;
      color: #007bff;
      padding: 2px;
    }
  }
}

@media screen and (max-width: 767px) {
  #get-started {
    .container {
      padding: 0;
      #search-row {
        width: 100%;
        margin: 0;
        #search-column {
          margin: 0 auto;
          display: contents;
          #search-box {
            margin-top: 0px;
            margin-bottom: 0px;
            min-height: 550px;
            #search-form {
              .form-group{
                .red-btn{
                  margin-top: 50px;
                }
              }
            }
          }
        }
      }

    }
  }

  #assessment-container {
    .container {
      padding: 0;
      #assessment-row {
        margin: 0;
        width: 100%;
        #assessment-box {
          #assessment-form {
            margin-top: 15px;
            margin-bottom: 20px;
            .section{
              .section-content{
                .form-group{
                  &.checklist{
                    padding-left: 10px;
                    span{
                      margin-left: 12px;
                    }
                  }
                  input[type='checkbox'] {
                    cursor: pointer;
                    height: 25px;
                    width: 25px;
                    margin-right: 10px;
                  }
                }
              }
            }
            .red-btn{

            }
          }
        }
      }
    }
  }

  #scheduler-container {
    width: 100%;
    margin: 0;
    .loader-container{
      top: 45%;
      left: 35%;
    }
    #scheduler-row {
      margin: 0;
      margin-top: 15px;
      margin-bottom: 25px;
      padding: 0 10px;
      width: inherit;
      .filter{
        width: 100% !important;
      }
      .scheduler-info {
        margin-top: 0;
        width: 100%;
        .alert{
          &.alert-secondary{
            width: 100% !important;
            padding:10px;
            h5{
              font-size: 18px;
            }
          }
        }
        .appointment-dates{
          .d-flex{
            &.flex-row{
              width: inherit;
              overflow-x: auto;
              padding-bottom: 12px;
            }
          }
          .appointment-date{
            box-shadow: none;
            min-width: 50px;
            &.active{
              box-shadow: none;
            }
          }
        }
      }
      .scheduler-box {
        margin-top: 15px;
        padding: 0;
        .location-container{
          padding: 10px;
          width: 100%;
          .alert{
            &.alert-info{
              width: 100% !important;
              padding:10px;
            }
          }
          .slots{
            overflow-x: auto;
            .red-btn-border {
              width: 100%;
            }
          }
        }
      }
    }
  }

  #visit-container {
    width: 100%;
    margin: 0;
    .loader-container{
      top: 45%;
      left: 35%;
    }
    #visit-row {
      margin: 0;
      margin-top: 15px;
      margin-bottom: 0;
      padding: 0 10px;
      width: inherit;
      .alert{
        &.alert-info{
          width: 100% !important;
          padding:10px;
        }
      }
      .visit-info {
        margin-bottom: 16px;
      }
      .alert{
        &.alert-secondary{
          width: 100% !important;
          padding:10px;
          h5{
            font-size: 18px;
          }
        }
      }
      .visit-heading{
        p {
          text-align: justify;
        }
      }
      .visit-form {
        .section{
          margin-bottom: 25px;
          h5{
            margin-top: 15px;
            font-weight: bold;
          }
          .section-content{
            padding: 10px;
            text-align: justify;
            .flex-basis {
              flex-direction: column !important;
              div{
                margin: 0 !important;
                padding-bottom: 10px;
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
            .address{
              flex-direction: column !important;
              div{
                margin-bottom: 16px;
              }
            }
          }
          .insurance-upload-container{
            flex-direction: column;
            width: 60%;
            .insurance-card {
              span{
                display: none;
              }
            }
            .upload-btn{
              margin-bottom: 20px;
            }
          }
        }
        .red-btn{
          width: 100%;
        }
      }
    }
  }

  #confirmation-container {
    width: 100%;
    margin: 0;
    .loader-container{
      top: 45%;
      left: 35%;
    }
    #confirmation-row {
      margin: 0;
      margin-top: 5px;
      margin-bottom: 0;
      padding: 10px;
    }
    .section{
      padding: 0 10px;
      .location-container{
        padding: 10px;
        width: 100%;
        margin: 0;
        .additional-info{
          p {
            margin-bottom: 4px;
            font-size: 14px;
            display: flex;
            align-items: center;
          }
        }
        .confirmation-code{
          .code{
            width: 80%;
            margin: 0 auto;
          }
        }
        .alert{
          &.alert-secondary{
            width: 100% !important;
            padding:10px;
            h5{
              font-size: 18px;
            }
          }
        }
        .alert{
          &.alert-info{
            width: 100% !important;
            padding:10px;
          }
        }
      }
    }
    .red-btn-border {
      width: 100%;
    }
    .form-group:last-child {
      margin: 10px;
    }
  }
}
