.breadcrumbs-main {
    background-color: #dedee0c2;
    border-top: 1px solid #707070;
    padding: 15px 0;
    margin-top: 140px;
    ul {
        padding-left: 0;
        list-style: none;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        li {
            cursor: pointer;
            padding: 0 6px;
            font-size: 18px;
            position: relative;
            color: #098180;
            a {
                text-decoration: none;
                color: #098180;
            }
            // &::after {
            //     position: absolute;
            //     content: "/";
            //     right: 0;
            // }
            // &:last-child {
            //     &::after {
            //         display: none;
            //     }
            //     a {
            //         cursor: text;
            //     }
            // }
        }
    }
}

