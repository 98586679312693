@import "../Styles/variables.scss";

//load
.load {
  padding: 30px 0;
  text-align: center;
  font-size: 20px;
  p {
    margin-bottom: 0;
    cursor: pointer;
    display: inline;
  }
}

//button
.white-btn {
  background-color: #fff;
  padding: 12px 40px;
  border: 1px solid;
  color: #000;
  border-radius: 50px;
  &:focus {
    outline: none;
  }
}

.table-btn {
  background-color: #fff;
  padding: 12px 40px;
  border: none;
  color: #000;
  border: 1px solid;
  &:focus {
    outline: none;
  }
}

.white-btn-border {
  background-color: transparent;
  padding: 12px 55px;
  color: #fff;
  border-radius: 50px;
  border: 2px solid;
  &:focus {
    outline: none;
  }
}

.red-btn {
  padding: 12px 40px;
  border: none;
  color: #fff;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 50px;
  background-color: $color-text;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.green-btn {
  @include green-gradient;
  padding: 12px 40px;
  border: none;
  color: #fff;
  border-radius: 50px;
  &:focus {
    outline: none;
  }
}

.red-btn-border {
  background-color: transparent;
  border: 2px solid #800020 !important;
  padding: 8px 40px;
  color: #800020;
  border-radius: 50px !important;
  text-decoration: none !important;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #fff;
    background-color: #800020;
  }
}

//custom-radio
.custom_radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked {
      ~ {
        .checkmark {
          background-color: #fff;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  &:hover {
    input {
      ~ {
        .checkmark {
          background-color: #fff;
        }
      }
    }
  }
  .checkmark {
    margin-top: 3px;
    &:after {
      top: 1.35px;
      left: 1.6px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: $color-background;
    }
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #707070;
  background-color: #fff;
  border-radius: 50%;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

//main-heading
.common-h2 {
  text-align: center;
  .heart-rate-description{
    font-size: 20px;
    letter-spacing: 2px;
    color: #098180;
  }
  .heart-rate-h2 {
    position: relative;
    font-weight: 600;
    text-align: center;
    display: inline;
    letter-spacing: 3px;
    &::before {
      position: absolute;
      top: -10px;
      content: "";
      background-image: url("../Images/heart-rate.png");
      width: 132px;
      height: 62px;
      background-repeat: no-repeat;
      left: 0;
      margin-left: -132px;
    }
    &::after {
      position: absolute;
      top: -10px;
      content: "";
      background-image: url("../Images/heart-rate.png");
      width: 132px;
      height: 62px;
      background-repeat: no-repeat;
      right: 0;
      margin-right: -132px;
    }
  }
}

.test-heading {
  color: $color-background;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  &::after {
    position: absolute;
    content: "";
    width: 100px;
    height: 4px;
    background-color: $color-background;
    bottom: -8px;
    left: 4px;
  }
}

//Common-card

.products-card {
  border: 1px solid #b7b7b7;
  position: relative;
  box-shadow: 0px 5px 30px #00000029;
  margin-bottom: 70px;
  min-height: 590px;
  .price-tag {
    position: absolute;
    top: 10px;
    background-color: #fff;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    right: 10px;
    color: #fff;
    p {
      margin-bottom: 0;
      color: #800020;
      font-size: 30px;
      cursor: pointer;
      z-index: 5;
    }
  }
  .products-img {
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-bottom: 1px solid #dbdbdb;
    background-color: #fff;
    img {
      width: 100%;
      &:hover{
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        transition: all .5s;
      }
    }
  }
  .coupon-code{
    color: #444;
    background-color: #c3e6cb;
    text-align: center;
    padding: 5px 0;
    span{
      font-weight: bold;
    }
  }
  .products-card-text {
    text-align: left;
    margin: 10px 0;
    padding: 0px 20px;
    h4 {
      font-weight: 700;
      margin-bottom: 20px;
      word-break: break-word;
      color: #098180;
    }
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .price-test {
      font-weight: 700;
      font-size: 24px;
    }
    .button-fixed {
      position: absolute;
      bottom: 30px;
      margin: 0 auto;
      width: 100%;
      left: 0;
    }
  }
}

//error
.error-validation {
  position: absolute;
  margin-bottom: 0 !important;
  bottom: -27px;
  left: 10px;
  color: red;
  padding-bottom: 0!important;
  font-size: 14px;
  &.zip-code{
    bottom: -7px;
  }
}

.unique-success {
  position: absolute;
  top: 85px;
  right: 50px;
  color: green;
}
.unique-error {
  position: absolute;
  top: 85px;
  right: 50px;
  color: red;
}

.error-msg {
  min-width: 300px;
  padding: 10px 20px;
  text-transform: uppercase;
  color: red;
  border: 2px solid;
  border-radius: 50px;
  margin: 0px 0px 0px auto;
  background-color: #ffe8e8;
  font-weight: 600;
  position: absolute;
  top: -30px;
  right: 0px;
  text-align: center;
  z-index: 1;
  span {
    margin-right: 5px;
  }
}

.success-msg {
  min-width: 300px;
  padding: 10px 20px;
  text-transform: uppercase;
  color: green;
  border: 2px solid;
  border-radius: 50px;
  margin: 0px 0px 0px auto;
  background-color: #e8fde5;
  font-weight: 600;
  position: absolute;
  top: -30px;
  right: 0px;
  text-align: center;
  z-index: 999;
  span {
    margin-right: 5px;
  }
  @media screen and (max-width: 767px) {
    line-height: 0px !important;
    font-size: 10px !important;
  }
}
.coupon_apply {
  border: none;
  background-color: #800020;
  color: #fff;
  padding: 5px;
}
.applied_message {
  border: 1px solid;
  padding: 10px 10px !important;
  font-size: 12px;
}
.spinner-border {
  font-size: 15px;
  width: 20px !important;
  height: 20px !important;
  margin-left: 20px;
  margin-bottom: 1px;
}

//form
.signup-form {
  width: 40%;
  margin: 0 auto;
  .form-group {
    position: relative;
    input {
      height: 45px;
      border: 1px solid #2b2b2b;
      border-radius: 10px;
      padding-left: 20px;
      margin-bottom: 25px;
      &::placeholder {
        color: #2b2b2b;
      }
    }
  }
  p {
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 25px;
    margin-bottom: 30px;
    a {
      color: #2b2b2b;
      text-decoration: underline;
    }
  }
  button {
    width: 100%;
    font-size: 20px;
  }
  .cb-org-user{
    span{
      color: #098180;
      letter-spacing: 1px;
    }
  }
}

//order-amount
.order-main-sum {
  background-color: #f5f5f5;
  padding: 20px;
  border: 1px solid #b7b7b7;
  box-shadow: 0px 7px 5px #00000029;
  h3 {
    padding-bottom: 10px;
    border-bottom: 1px solid #cdcdcd;
  }
  .item {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    margin-bottom: 5px;
    p{
      font-size: 16px;
      &:first-child{
        font-size: 18px;
      }
      margin-bottom: 0;
    }
  }
  .coupon-btn {
    border: 1px dashed;
    padding: 10px 30px;
    border-radius: 40px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .coupon-img {
      width: 30px;
      height: 30px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      img {
        width: 100%;
      }
    }
  }
  .total-div {
    border-top: 1px solid #cdcdcd;
    padding-top: 20px;
    padding-bottom: 0;
    margin-bottom: 5px;
    p{
      font-size: 16px;
      &:first-child{
        font-size: 18px;
      }
      margin-bottom: 0;
    }
  }
}

//list
.signin-process {
  margin-top: 60px;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
  ul {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-around;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 14px;
      left: 0px;
      border-bottom: 1px solid #ddd;
      width: 100%;
      height: 2px;
    }
    li {
      list-style: none;
      p {
        color: #707070;
        &.active {
          color: $color-background;
          font-weight: 700;
        }
      }
      .check-round {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ddd;
        border-radius: 100%;
        position: relative;
        margin: 0px auto 15px;
        .step{
          color: #444;
        }
        i{
          &.fas{
            font-size: 14px;
            color: #444;
          }
        }
        &.active {
          background-color: $color-background;
          .step{
            color: #fff;
          }
          i{
            &.fas{
              font-size: 14px;
              color: #444;
            }
          }
        }
      }
    }
  }
}

//track-order-box
.track-order-box {
  background-color: #ffffff;
  padding: 15px;
  border: 0.5px solid #ced4da;
  margin-bottom: 20px;
  width: 70%;
  z-index: -1;
  ul {
    display: flex;
    justify-content: space-between;
    margin: 0px;
    padding: 0px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 35px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #989898;
      z-index: 1;
    }
    li {
      text-align: center;
      list-style: none;
      display: flex;
      flex-flow: column;
      align-items: center;
      background-color: #fff;
      position: relative;
      z-index: 2;
      width: 110px;
      .track-order-icon {
        min-width: 70px;
        height: 70px;
        width: 70px;
        background-color: #fff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        border: 1px solid #989898;
        i {
          color: #989898;
          font-size: 25px;
        }
      }
      p {
        color: #000000;
        font-size: 13px;
        margin-bottom: 0px;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
      }
      &.active {
        .track-order-icon {
          border-color: $color-background;
          background-color: $color-background;
          i {
            color: #fff;
          }
        }
      }
    }
  }
}

//Media Queries

@media (max-width: 1200px) and (min-width: 992px) {
  .white-btn {
    padding: 10px 30px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .red-btn-border {
    padding: 12px 34px;
  }
}

@media screen and (max-width: 767px) {
  .common-h2 {
    .heart-rate-h2 {
      font-size: 20px;
      &::before {
        left: 62px;
        width: 50%;
        height: 70px;
        top: -18px;
      }
      &::after {
        right: 62px;
        width: 50%;
        height: 70px;
        top: -18px;
      }
    }
  }
}
