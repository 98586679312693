@import "../../Styles/variables.scss";

.shipping-main {
  margin-bottom: 80px;
  .sigin-desc {
    h2 {
      text-align: left;
      margin-top: 40px;
      font-weight: 600;
      padding-bottom: 25px;
      border-bottom: 2px solid #e2e2e2;
      margin-bottom: 60px;
    }
  }
  .shipping-form {
    margin-top: 40px;
    form {
      h4 {
        border-bottom: 1px solid #707070;
        padding-bottom: 20px;
        margin-bottom: 25px;
      }
      .form-group {
        position: relative;
        input,
        select {
          height: 45px;
          border: 1px solid #2b2b2b;
          border-radius: 10px;
          padding-left: 20px;
          margin-bottom: 30px;
        }
        textarea{
          border: 1px solid #2b2b2b;
          border-radius: 10px;
          padding-left: 15px;
          margin-bottom: 30px;
        }
        .form-check {
          label {
            input[type="checkbox"] {
              height: 15px;
            }
          }
        }
      }
    }
    .cart-return-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      a {
        color: #800020;
        text-decoration: none;
        font-size: 18px;
        cursor: pointer;
        &:hover{
          color: #800020b0;
        }
        i {
          color: #800020;
        }
        span {
          margin-right: 10px;
          color: #000;
        }
      }
    }
    .form-check-label{
      span{
        color: #098180;
        letter-spacing: 1px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .shipping-main {
    margin-bottom: 20px;
    .sigin-desc {
      h2 {
        margin-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0px;
        font-size: 24px;
      }
    }
    .shipping-form {
      margin-top: 10px;
      form {
        h4 {
          font-size: 20px;
          padding-bottom: 3px;
          margin-bottom: 15px;
        }
        .form-group {
          position: relative;
          input,
          select {
            height: 35px;
            font-size: 14px;
            border-radius: 5px;
            padding-left: 10px;
            margin-bottom: 25px;
          }
          p{
            &.error-validation{
              left: 3px;
              bottom: -20px;
              font-size: 12px;
            }
          }
          label{
            font-size: 14px;
            margin-bottom: 0;
          }
        }
      }
      .cart-return-btn {
        margin: 10px 0px;
        a{
          font-size: 14px;
          span{
            margin-right: 0;
          }
        }
      }
      .form-check-label{
        span{
          letter-spacing: 0px;
        }
      }
    }
    .order-main-sum{
      padding: 10px 20px;
      h3{
        font-size: 22px;
      }
    }
  }
}
